import mixpanel from 'mixpanel-browser';
import { isProduction, mixpanelApiHost, mixpanelToken } from '../services/env';
import { useEffect } from 'react';

class FacebookPixel {
  constructor() {
    this.fbq = null;
    this.isInitialized = false;
  }

  init() {
    this.fbq = window.fbq;
    this.isInitialized = this.fbq && typeof this.fbq === 'function';
  }

  track(eventName, values = {}, eventId = '') {
    if (!this.isInitialized) {
      return;
    }

    this.fbq('track', eventName, values, { eventID: eventId });
  }
}

class GoogleTag {
  track(eventName, values = {}) {
    if (!window.gtag || typeof window.gtag !== 'function') {
      return;
    }

    window.gtag('event', eventName, values);
  }
}

const facebookPixel = new FacebookPixel();
const googleTag = new GoogleTag();

function checkShouldTrack(...args) {
  if (!isProduction) {
    console.log('Mixpanel: ', ...args);
    return false;
  }

  if (!mixpanelToken) {
    return false;
  }

  return true;
}

function initTracking() {
  if (!checkShouldTrack('init')) {
    return;
  }

  mixpanel.init(mixpanelToken, {
    api_host: mixpanelApiHost,
    debug: process.env.NODE_ENV !== 'production',
    track_pageview: true,
    ip: true,
  });
  facebookPixel.init();
}

function useTracking() {
  useEffect(() => {
    initTracking();
  }, []);

  return null;
}

function trackEvent(eventName, values = {}) {
  if (!checkShouldTrack('trackEvent', eventName, values)) {
    return;
  }

  try {
    mixpanel.track(eventName, values);
  } catch (e) {
    // no-op
  }

  if (eventName === 'Signup') {
    const eventId = values.userId;

    facebookPixel.track('CompleteRegistration', {}, eventId);

    facebookPixel.track(
      'StartTrial',
      {
        value: values.predicted_ltv,
        currency: values.currency,
      },
      eventId,
    );
  }

  if (eventName === 'Subscribe') {
    const eventId = values.subscriptionId;

    facebookPixel.track(
      'Subscribe',
      {
        value: values.predicted_ltv,
        currency: values.currency,
        subscription_id: values.subscriptionId,
        plan_id: values.plan_id,
      },
      eventId,
    );

    googleTag.track('conversion', {
      send_to: 'AW-16833295745/h9KqCKSLnJIaEIHr3to-',
      value: values.predicted_ltv,
      currency: values.currency,
      transaction_id: eventId,
    });
  }
}

function identifyUser(userId) {
  if (!checkShouldTrack('identifyUser', userId)) {
    return;
  }

  try {
    mixpanel.identify(userId);
  } catch (e) {
    // no-op
  }
}

function updateUser(values = {}) {
  if (!checkShouldTrack('updateUser', values)) {
    return;
  }

  try {
    mixpanel.people.set(values);
  } catch (e) {
    // no-op
  }
}

function setUser(values = {}) {
  if (!checkShouldTrack('setUser', values)) {
    return;
  }

  try {
    mixpanel.people.set_once(values);
  } catch (e) {
    // no-op
  }
}

function logout() {
  if (!checkShouldTrack('logout')) {
    return;
  }

  try {
    mixpanel.reset();
  } catch (e) {
    // no-op
  }
}

function trackPageview() {
  if (!checkShouldTrack('trackPageview')) {
    return;
  }

  try {
    mixpanel.track_pageview();
  } catch (e) {
    // no-op
  }
}

const tracking = {
  identifyUser,
  trackEvent,
  setUser,
  updateUser,
  logout,
  trackPageview,
};

export { useTracking, tracking };
